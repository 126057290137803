// Before click on container
.css-13cymwt-control {
  border-radius: 0.625rem!important;
  padding: 2px 0px;
  [data-theme="light"] & {
    background-color: white;
    border: $input-border-width solid var(--kt-gray-600);
    color: var(--kt-input-solid-bg);
  }

  [data-theme="dark"] & {
    background-color: var(--kt-input-solid-bg);
    border: $input-border-width solid var(--kt-input-border-color);
    color: var(--kt-input-solid-color);
  }


}

// Separator between arrow down and dropdown
.css-1u9des2-indicatorSeparator {
  background-color: var(--kt-input-placeholder-color) !important;
}

// Arrow down
.css-tj5bde-Svg {
  color: var(--kt-input-placeholder-color)
}

// Placeholder
.css-1jqq78o-placeholder {
  [data-theme="dark"] & {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--kt-input-placeholder-color);
  }

  [data-theme="light"] & {
    font-size: 1.1rem;
    font-weight: 500;
    color: var(--kt-input-placeholder-color);
  }
}

// Input
.css-166bipr-Input{
  font-size: 1.15rem;
  font-weight: 500;
  [data-theme="dark"] & {
    color: white;
  }
  [data-theme="dark"] & {
    color: darkgrey;
  }
}

// Input prompt ( avant de saisir l'input )
.css-qbdosj-Input{
  [data-theme="dark"] & {
    color: white;
  }
  [data-theme="dark"] & {
    color: darkgrey;
  }
}

.css-qbdosj-Input input, .css-166bipr-Inpu input{
  margin-left: 10px!important;
}

// After click on container
.css-t3ipsp-control { // TODO
  [data-theme="dark"] & {
    background-color: var(--kt-input-solid-bg-focus);
    color: var(--kt-input-solid-color);
    border-color: var(--kt-input-border-color);
    box-shadow: 0 0 0 1px var(--kt-input-border-color);

    &:hover{
      border-color: var(--kt-input-border-color);
      box-shadow: 0 0 0 1px var(--kt-input-border-color);
    }
  }

  [data-theme="light"] & {
    background-color: var(--kt-input-solid-bg);
    color: var(--kt-input-solid-color);
    border-color: var(--kt-input-border-color);
    box-shadow: 0 0 0 1px var(--kt-input-border-color);

    &:hover{
      border-color: var(--kt-input-border-color);
      box-shadow: 0 0 0 1px var(--kt-input-border-color);
    }
  }
}


// Options container
.css-1nmdiq5-menu{
  [data-theme="dark"] & {
    background-color: var(--kt-input-solid-bg);
    color: white;
    border: 0.2rem solid var(--kt-input-border-color)
  }
}

// Unselected option in dropdown
.css-10wo9uf-option{
  [data-theme="dark"] & {
    &:hover{
      background-color: white;
      color: var(--kt-input-solid-bg) ;
    }
  }
}

// Selected option in dropdown
.css-d7l1ni-option{
  [data-theme="dark"] & {
    background-color: white;
    color: var(--kt-input-solid-bg);
  }
}

// Selected option in input
.css-1dimb5e-singleValue{
  margin-left: 10px!important;
  font-size: 1.15rem;
  font-weight: 500;
  [data-theme="dark"] & {
    color: var(--kt-input-solid-color)
  }
}


