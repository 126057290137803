// BEGIN:: TEMPLATE REPLACEMENTS

// Confirm & cancel button SWAL2
.swal2-styled.swal2-cancel {
  min-width: 80px;
}

.swal2-styled.swal2-confirm {
  min-width: 80px;
  margin-right: 15px;
}

// input-sm = btn-sm height
.input-sm {
  padding: calc(0.55rem + 1px) calc(1.25rem + 1px);
  font-size: 0.925rem;
  border-radius: 0.425rem
}

// Container max height
.swal2-container .swal2-html-container {
  max-height: 250px !important;
}


// Text color black for button warning (the white hurts eyes)
.btn.btn-warning {
  color: black !important;
}

.btn.btn-warning > * {
  color: black !important;
}

// Left menu
.menu-sub-indention .menu-sub:not([data-popper-placement]) {
  margin-left: 2rem!important;
}


// Modal

// -- Modal header too big
.modal-header {
  padding: 1.25rem 1.25rem !important;
}

// END:: TEMPLATE REPLACEMENT


// BEGIN:: CUSTOM

/* CUSTOM WIDTHS */
