.heuresCell:hover {
  background: rgba(0, 149, 232, 0.5)!important;
}


.isWeekendCell {
  background-color: rgba(140, 140, 140, 0.3)!important;
  [data-theme="dark"] & {
    background-color: rgba(181, 181, 195, 0.3)!important;
  }
}

.isTodayCell:after {
  background: var(--kt-danger-active)!important;
  content: '\00a0';
  height: 200vh;
  left: 80%;
  position: absolute;
  top: -100vh;
  width: 1%;
  pointer-events: none;
  //background-color: rgba(241, 65, 108, 0.2)!important;
}

#cra-table {
  overflow-x: visible;
}

#cra-table tbody td, #cra-table tbody th {
  position: relative;
}

.cra-table-edition-mode td{
  cursor: pointer;
}

#cra-table tbody tr:hover{
  background: rgba(0, 149, 232, 0.2)!important;
}

#cra-table tbody td:not(.sticky-column):hover::after {
  background: rgba(0, 149, 232, 0.2) !important;
  content: '\00a0';
  height: 200vh;
  left: 0;
  position: absolute;
  top: -100vh;
  width: 100%;
  pointer-events: none;
  z-index: 1;
}


.sticky-column {
  position: sticky!important;
  left: 0!important;
  background-color: var(--kt-dark-inverse)!important;
  z-index: 2;
}