.swal2-popup {
  [data-theme="light"] & {
    background-color: white;
  }

  [data-theme="dark"] & {
    background-color: var(--kt-modal-bg);
  }
}


.swal2-textarea{
  [data-theme="light"] &::placeholder {
    color: var(--kt-input-placeholder-color);
  }

  [data-theme="dark"] &::placeholder {
    color: var(--kt-input-placeholder-color);
  }

  [data-theme="light"] &:focus {
    color: var(--kt-input-color);
  }

  [data-theme="dark"] &:focus {
    color: var(--kt-input-color);
  }
}



.swal2-textarea{
  [data-theme="light"] & {
    color: var(--kt-input-placeholder-color);
  }

  [data-theme="dark"] & {
    color: var(--kt-input-placeholder-color);
  }
}
.swal2-confirm{
  background-color: #3085d6!important;
}

.swal2-cancel{
  background-color: #d33!important;
}



